import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "@config/siteConfig";

// import qs from "qs";

const _api = {
  get_shipments: async function (token) {
    return axios
      .get(API_URL + "shipment/", {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },

  delete_shipments: async function (id,token) {
    return axios
      .delete(API_URL + "shipment/?id="+id, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        if(response.data.status!==200){
          Swal.fire(
            'Error!',
           response?.data?.message,
            'error',
          );
        }
        return response?.data;
      })
      .catch(function (error) {
        Swal.fire(
          'Error!',
            "Some thing Went wrong!",
          'error',
        );
        return error?.response.data;
      });
  },

  create_shipments: async function (data, token) {
    return axios
      .post(API_URL + "shipment/", [data], {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
   
        if(response.data.status!==200){
          Swal.fire(
            'Error!',
           response?.data?.message,
            'error',
          );
        }
        return response?.data;
      })
      .catch(function (error) {
        Swal.fire(
          'Error!',
            "Some thing Went wrong!",
          'error',
        );
        return error?.response.data;
      });
  },


  update_shipment: async function (data, token) {
    return axios
      .put(API_URL + "shipment/", data, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  },

  import_shipments: async function (data, token) {
    return axios
      .post(API_URL + "import-shipment/", data, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },

  get_imported_shipments: async function (token) {
    return axios
      .get(API_URL + "import-shipment/", {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },

};
export default _api;
