import { lazy } from "react";

export const appRoutes = {
  Restricted: [
    {
      path: "/account-setup",
      component: lazy(() => import("@pages/Account-setup")),
    },
    {
      path: "/email-verification",
      component: lazy(() => import("@pages/EmailVerification")),
    },
    {
      path: "/terms-and-conditions",
      component: lazy(() => import("@pages/Agreement")),
    },
    {
      path: "/verify/:token",
      component: lazy(() => import("@pages/Verification")),
    
    },
  ],
  //   Declare Private Route Here
  Private: [
    {
      path: "/dashboard",
      component: lazy(() => import("@pages/Dashboard")),
    },
    {
      path: "/notifications",
      component: lazy(() => import("@pages/Notifications")),
    },
    {
      path: "/shipments",
      component: lazy(() => import("@pages/Shipments")),
    },
    // {
    //   path: "/shipment/create",
    //   component: lazy(() => import("@pages/Shipment/create")),
    // },
    {
      path: "/profile",
      component: lazy(() => import("@pages/Profile")),
    },
    {
      path: "/imports",
      component: lazy(() => import("@pages/Import-Shipments")),
    },
    {
      path: "/batches",
      component: lazy(() => import("@pages/Batches")),
    },
    {
      path: "/batch/:id",
      component: lazy(() => import("@pages/Batches/Batch")),
    },
    {
      path: "/pickups",
      component: lazy(() => import("@pages/Pickups")),
    },
    {
      path: "/prep-pack",
      component: lazy(() => import("@pages/PrepPack")),
    },
    {
      path: "/account-settings",
      component: lazy(() => import("@pages/Account-Settings")),
    },
    {
      path: "/connect",
      component: lazy(() => import("@pages/Connect")),
    },
    {
      path: "/verify/:token",
      component: lazy(() => import("@pages/Verification")),
    
    },
    
  ],

  // Declare Public Route Here
  Public: [
    {
      path: "/verify/:token",
      component: lazy(() => import("@pages/Verification")),
    
    },
   
    {
      path: "/register",
      component: lazy(() => import("@pages/Register")),
    },
    {
      path: "/login",
      component: lazy(() => import("@pages/Login")),

    },
    {
      path: "/",
      component: lazy(() => import("@pages/Login")),
      exact: true,
    },
    {
      path: "/forgot-password",
      component: lazy(() => import("@pages/Forgot-Password")),
      exact: true,
    },
    {
      path: "/update-password",
      component: lazy(() => import("@pages/Update-Password")),
      exact: true,
    },
    


  ],
};
