import axios from 'axios'
import {USPS_API} from '@config/siteConfig'
import qs from 'qs'
const Service = {
    validateAddress:async function(data){
        let xmlBody = '<AddressValidateRequest USERID="270BUYBO5441"><Revision>1</Revision><Address ID="1"><Address1>'+data.Address2+'</Address1><Address2>'+data.Address1+'</Address2><City>'+data.City+'</City><State>'+data.Province+'</State><Urbanization>YES</Urbanization><Zip5>'+data.PostalCode+'</Zip5><Zip4>'+data.PostalCode+'</Zip4></Address></AddressValidateRequest>';
        return axios.get(USPS_API+'?API=Verify&XML='+xmlBody,{
            headers:{
                'Content-Type': 'application/xml'
            }
        }).then(response=>response.data).catch(error=>error.response);
    }
}
export default Service;