import { _types } from "../types";
const { REQ, SIGNIN, SIGNOUT, REGISTER,ACCOUNT_SETUP,VERIFY,AGREEMENT,RESEND_EMAIL} = _types;
const actions = {
  signIn: (payload) =>
    ({
      type: SIGNIN + REQ,
      payload: payload,
    }),
  signOut: () =>

    ({
      type: SIGNOUT + REQ,
    }),
  register: (value) =>
    ({
      type: REGISTER + REQ,
      payload: value,
    }),
  account_setup: (value) =>
    ({
      type: ACCOUNT_SETUP + REQ,
      payload: value,
    }),
  verify_user: (value) =>
    ({
      type: VERIFY + REQ,
      payload: value,
    }),
  resend_email: (value) =>
    ({
      type: RESEND_EMAIL + REQ,
      payload: value,
    }),
  verify_user: (value) =>
    ({
      type: VERIFY + REQ,
      payload: value,
    }),
  agree_user: (value) =>
    ({
      type: AGREEMENT + REQ,
      payload: value,
    }),
};
export default actions;
