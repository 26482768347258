export const API_URL="https://buyboxexpress.com/api/"

export const CAD_to_US = 0.74;
export const US_to_CAD = 1.34;

// export const PayPal_Secret_key = 'EJZqNBxmm45qvif0W1wTebkJ6XQDG-afIaLhnqk2cYKm6Bn4-EYaCFr_m23VJmisIwTs_0nof8vVRIG_';
// export const PayPal_Client_id = 'AYSOARC16jPnBDWF5YTVQtuQm3HwyuPjird2F-36uk5kVaxKpc2Nb5kWDvUgWrbJTaFEIyII5IF3o2Kw';

export const Sendbox_Account = 'sb-kqfmx22698240@business.example.com';
export const PayPal_Secret_key = 'EOtH79y0PhWsFEHPpJhFw11W0j6XXEU7Z1PetMAJZk_vrBSYL51lCBoLIKi1DoNjda5_sA4RNRqelreE';
export const PayPal_Client_id = 'AXdyF7HUtPWXM09PWIJNiYXIfO9abSBbrQTawYk6kvuxX7fCCXxM5_6AMKKNioHXd2qKJlhr1gZJvVHN';

// export const STRIPE_API_KEY = 'sk_test_51JAoAZFykSQXJfaDlnGz0MlVTIa5BWmQWyCnSIeQyavCNlrjiTwPkOioIuYV8D4bSJnKcRd0nW2R1DPOdiPk7p6E00j2mqtLYS';
// export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51JAoAZFykSQXJfaDsSLUMoIpiqnPGivLY7Zl450sTBtszFVIGhMEuvcgCj6MAiBXLVmMBkzL1C8uE1QpkU03xmWk00zdPXRWMY';

export const STRIPE_API_KEY = 'pk_live_51JAoAZFykSQXJfaD3zkrezjhFNOcpEWu8CVmC0wqqmY76KXvIxwmQIyfgbccyax299pmDiSESBRCHvXGitRSpXn400mKd9lWSl';
export const STRIPE_PUBLISHABLE_KEY = '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';

// Staging
// export const USPS_API = 'https://stg-secure.shippingapis.com/ShippingAPI.dll';
// Live
export const USPS_API  = 'https://secure.shippingapis.com/ShippingAPI.dll';