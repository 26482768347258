import { createStore, applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import reduxlogger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware,reduxlogger];

const createStoreWithMiddleware = applyMiddleware(...middlewares);
let store = createStore(rootReducer, createStoreWithMiddleware, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { store, persistor };
