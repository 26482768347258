import { _types } from "../types";
const { ACTIVE_MENU, SCREEN_SIZE, ACTIVE_NOTIFICATION, ACTIVE_USER_PROFILE, IS_CLICKED } = _types
const initialState = {
    ACTIVE: {
        active_menu: false,
        screen_size: null,
    },
    CLICKED: {
        active_notification: false,
        active_user_profile: false,
    }
}

const { ACTIVE: { active_menu, screen_size }, CLICKED } = initialState

export const isClickedReducer = (state=CLICKED, { type, payload }) => {
    switch (type) {
        case IS_CLICKED:
            return {...payload}
    
        default:
            return state;
    }
}

export const activeMenuReducer = (state=active_menu, { type, payload }) => {
    switch (type) {
        case ACTIVE_MENU:
            return payload
    
        default:
            return state;
    }
}

export const screenSizeReducer = (state=screen_size, { type, payload }) => {
    switch (type) {
        case SCREEN_SIZE:
            return payload
    
        default:
            return state;
    }
}

// export const activeNotificationReducer = (state=active_notification, { type, payload }) => {
//     switch (type) {
//         case ACTIVE_NOTIFICATION:
//             return {...state, payload}
    
//         default:
//             state
//     }
// }

// export const activeProfileReducer = (state=active_user_profile, { type, payload }) => {
//     switch (type) {
//         case ACTIVE_USER_PROFILE:
//             return {...state, payload}
    
//         default:
//             state
//     }
// }