import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { Input, Form, Select, Typography, Button, Steps } from 'antd';
import { LabelProvider } from '@services/LabelPrinting'
import Shipment_Action from '@redux/shipment/actions';
import LoadingScreen from "@components/LoadingScreen";
import { usePopupHandler } from '@layouts/PopupHandler'

// Pages and Forms
import Recipient from './forms/Recipient'
import VerifyAddress from './forms/VerifyAddress'
import Contents from './forms/Contents'
import PackageDetails from './forms/PackageDetails'
import Postage from './forms/Postage'
import Summary from './forms/Summary'

// Render Pages Handler
const renderStepContent = (step, form, setAddressValid,thirdpatyList) => {
  switch (step) {
    case 0:
      return <Recipient form={form} />
    case 1:
      return <VerifyAddress form={form} setAddressValid={setAddressValid} />
    case 2:
      return <Contents form={form} />
    case 3:
      return <PackageDetails form={form} />
    case 4:
      return <Postage form={form} thirdparty={thirdpatyList} />
    case 5:
      return <Summary form={form} />
  }
}

export default function ShipmentCreate() {
  
  const [form] = Form.useForm();
  const { closeModal } = usePopupHandler()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Auth = useSelector((state) => state.Auth?.auth);
  const Shipment = useSelector((state) => state?.Shipment);
  const [serviceList,setServiceList] =  useState([])
  const [FormState, setFormState] = useState({
    name: "",
    address1: "",
    address2: "",
    country: null,
    city: null,
    province: null,
    postal_code: "",
    phone: "",
    tax_id: "",
    email: "",
    package_content: "",
    package_description: "",
    retail_value: "",
    package_weight: null,
    pick_type: null,
    order_id: null,
    has_postage: null,
    package_type: "Buybox",
    package_type_option: "",
    is_cross_border: true,
    package_length: null,
    package_width: null,
    package_height:null,
    package_size_unit: 'cm',
  })
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAddressValid, setAddressValid] = useState(false)
  const [postage, setPostage] = useState(false)

  let thirdpatyList={
    get:function(){
      return serviceList
    },
    set:function(val){
      setServiceList(val)
    }
  }

  // Step Label Generator
  const steps = ["Recipient", "Verify Address", "Contents", "Package Details", "Postage Info", "Summary"]
  const items = steps.map((item) => ({
    key: item,
    title: item,
  }));

  // Real Time Address Setter
  useEffect(() => {
    setAddressValid(form.getFieldValue('validAddress'))
    setPostage(true)
  }, [form.getFieldValue('validAddress'), form.getFieldValue('has_postage')])

// Previous and Next Handler
  const next = () => {
    debugger
    form
      .validateFields()
      .then((response) => {
        debugger
        setActiveStep(activeStep + 1)
      })
      .catch((error) => console.log(error));
  };
  const prev = () => {
    setActiveStep(activeStep - 1)
  };

  // Handle Final Submission
  const handleSubmit = async () => {
    let data = form.getFieldsValue(true);
    
    if (typeof form.getFieldValue('postage_type') === "object") {
      data = { ...form.getFieldsValue(true), postage_type: form.getFieldValue('postage_type')?.service };
    }
    Object.assign(data, {barcodeRequired: false});
    // Object.assign(data, {"user_details": Auth?.user?.account});

    // data.from_city = Auth?.user?.account?.city;
    let res = await LabelProvider["USPS"](data,Auth?.user?.account);
    data["tracking_code"] = res;
    dispatch(Shipment_Action.create_shipment({ data, token: Auth?.token }))
  }

// Success Response Handler
  useEffect(() => {
    if (Shipment.isSuccess) {
      Swal.fire(
        'Successfull',
        Shipment?.successText,
        'success',
      )
      closeModal()
      form.resetFields();
    }
  }, [Shipment.isSuccess])

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className='header py-4 px-4 bg-[#00443c]'>
        <div className='flex w-full justify-between'>
          <div>    <Typography.Title level={3}>
            <span className='text-white'>    Create New Shipment</span>
          </Typography.Title></div>
          <div> <Button type='text' className='text-white' onClick={() => closeModal()} >
            Close
          </Button></div>
        </div>
      </div>
      <Form
        initialValues={FormState}
        form={form}
        layout="vertical"
      >

        <div className='content-center justify-center items-center contents min-h-screen'>
          <div className="shadow-lg p-8 mb-6">
            <Steps current={activeStep} items={items} size="small" />
          </div>
          <div className="shadow-xl p-6 mt-[7.292vw] flex flex-col items-center justify-center text-center border xs:w-[350px] sm:w-[550px] md:w-[800px] my-auto mx-auto">
            <div className="steps-content w-full">{renderStepContent(activeStep, form, setAddressValid,thirdpatyList)}</div>
            <div className="steps-action">
              {activeStep < steps.length - 1 && (
                <Button type="primary" 
                disabled={activeStep == 1 ? isAddressValid || !postage : false} className={activeStep == 1 ? isAddressValid ? "pointer-events-none" : "pointer-events-auto" : "pointer-events-auto"} onClick={() => next()}>
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button type="primary" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              )}
              {activeStep > 0 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}