import { _types } from "../types";

const { STATE, CLEAR, FAILD, SUCCESS,BATCH_LIST ,BATCH, CREATE_BATCH, UPDATE_BATCH,DELETE_BATCH } = _types;
const initState = {
  isProgress: false,
  batches: null,
  batch_items: null,
  response: null,
  isError: false,
  errorText: "",
  isSuccess: false,
  successText: "",
};
function batchReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        isProgress: true,
      };

    case BATCH_LIST + SUCCESS:
      return {
        ...state,
        batches: action.payload
      }
    case BATCH + SUCCESS:
      return {
        ...state,
        batch_items: action.payload
      }
    case CREATE_BATCH + SUCCESS:
      return {
        ...state,
 
        successText: "New Batch Created Successfully",
      };

      case DELETE_BATCH + SUCCESS:
        return {
          ...state,
          isSuccess: true,
          successText: "Batch deleted Successfully",
        };
   
    case UPDATE_BATCH + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isBatchUpdated: true,
        successText: "Batch Updated Successfully"
      }
    case STATE + CLEAR:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        isSuccess: false,
        successText: "",
      };
    default:
      return state;
  }
}

export default batchReducer;
