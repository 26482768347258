import React, { useState, useEffect } from 'react'
import { Form, Select, Typography, Space } from 'antd';
import { RatesProvider } from '@services/Pricing'



const Postage = ({ form,thirdparty }) => {
  const [uspsServices,setUSPSServices]=useState(thirdparty.get())
  const [SelectService, setSelectedSevice] = useState(null);
  const [price, setPrice] = useState(form.getFieldValue('shipment_amount')?form.getFieldValue('shipment_amount'):0);

  // Extracted Data
  let Country = form.getFieldValue('country');
  let Province = form.getFieldValue('province');
  let City = form.getFieldValue('city');
  let PostalCode = form.getFieldValue('postal_code');
  let Address1 = form.getFieldValue('address1');
  let Address2 = form.getFieldValue('address2');
  let is_crossborder = form.getFieldValue('is_cross_border')
  let has_postage = form.getFieldValue('has_postage');
  let Data = form.getFieldsValue(true);


// Normal Price Handler
  async function priceHandler(val) {
    let data = await val;
    setPrice(data?.totalPrice);
  }

// USPS Price Handler
  async function uspsPriceHandler(val) {
    let data = await val;
    setUSPSServices(data?.Package.Postage)
    thirdparty.set(data?.Package.Postage)
  }


  // Trigger On Render
  useEffect(()=>{
    if(has_postage && Country==="United States"){
      let Get_rates = RatesProvider["USPS"];
      let RatesData = Get_rates(Data);
      uspsPriceHandler(RatesData);
    }else{
      servicehandler(form.getFieldValue('postage_type'))
    }
  },[])


  // set Shipment Price
  useEffect(()=>{
    if(price>0){
      form.setFieldValue('shipment_amount',price)
      form.setFieldValue('postage_type',SelectService)
      form.setFieldValue('postage_type_buybox',SelectService)
    }
  },[price])




// Service and Price Handler
  function servicehandler(e) {
    setSelectedSevice(e);
    let Get_rates = RatesProvider["Buybox"];
    let RatesData = Get_rates(Data);
    priceHandler(RatesData);
  }

  var postageOptions = [
      {
        value: 'USPS-First Class',
        label: 'USPS First Class Mail',
      },
      {
        value: 'USPS-Priority',
        label: 'USPS Priority Mail',
      },
      {
        value: 'USPS-Parcel Select Ground',
        label: 'USPS Parcel Select Ground'
      },
      
    ]
  


  return (
    <div className="grid">
      <Typography.Title level={3}>Postage</Typography.Title>
      <div className='grid '>
        <p className='text-left'>Estimates from various carriers based on the information you have inputted. Charge backs may apply as the carriers reassess the shipments.
          <br />
          Carriers reassess all package weight and dimensions. What are postage adjustments?</p>
      </div>
      {/* Row 1 */}
      <div className="grid-flow-row py-2">
        <div>
          <p className='text-left'><b>Postage Type</b></p>
            <div className='grid grid-cols-2 gap-4'>
            {has_postage === "No" ? null : (
              <Form.Item
                name="postage_type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Postage Type"
                  onChange={servicehandler}
                  options={postageOptions}
                />
              </Form.Item>
            )}
            {!is_crossborder? null : (
              <Form.Item name="postage_type_buybox"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select BuyBox Type"
                  onChange={servicehandler}
                  options={[
                    {
                      value: 'Buybox',
                      label: 'Buybox Express',
                    }
                  ]}
                />
              </Form.Item>
              )}
            </div>
            <div style={{color: 'red',padding: '5px',fontSize: '12px',textAlign:'left',marginTop:'-30px',width: 'max-content', }}> 
               <strong>◇  Buybox crossborder shipment are handed over to the designated carrier across the border in the United States</strong>
            </div>
          {SelectService && price>0 ?
            <div className='grid'>
              <p className='text-left'><b>Summary</b></p>
              <div className='grid gap-2 border-b border-gray-300 hover:bg-gray-100 p-2 grid-cols-2'>
                <div className='text-left' >Rate</div>
                <div className='text-right' >${price}</div>
              </div>


              <div className='grid gap-2 grid-cols-2 bg-gray-100 p-2'>
                <div className='text-left'><b>Total</b></div>
                <div className='text-right'>${price}</div>

              </div>
            </div> : null}
        </div>
      </div>
    </div>
  )
}

export default Postage
