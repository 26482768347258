export const _types = {
  // Global
  REQ: "_REQ",
  RES: "_RES",
  FAILD: "_FAILD",
  SUCCESS: "_SUCCESS",
  CLEAR: "_CLEARING",
  PROGRESS: "_PROGRESS",
  STATE: "RESPONSES",

  // Auth
  SIGNIN: "SIGNIN",
  SIGNOUT: "SIGNOUT",
  REGISTER: "REGISTER",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  ACCOUNT_SETUP: "ACCOUNT_SETUP",
  VERIFY: "VERIFY",
  AGREEMENT: "AGREEMENT",
  RESEND_EMAIL: "RESEND_EMAIL",

  // SHIPMENTS
  SHIPMENT_LIST: "SHIPMENT_LIST",
  CREATE_SHIPMENT: "CREATE_SHIPMENT",
  IMPORTED_SHIPMENT_LIST: "IMPORTED_SHIPMENT_LIST",
  SET_IMPORT_SHIPMENT: "SET_IMPORT_SHIPMENT",
  IMPORT_SHIPMENT: "IMPORT_SHIPMENT",
  UPDATE_SHIPMENT: "UPDATE_SHIPMENT",
  EDIT_SHIPMENT: "EDIT_SHIPMENT",
  DELETE_SHIPMENT: "DELETE_SHIPMENT",

  // BATCH
  BATCH: "BATCH",
  BATCH_LIST: "BATCH_LIST",
  CREATE_BATCH: "CREATE_BATCH",
  DELETE_BATCH: "DELETE_BATCH",
  UPDATE_BATCH: "UPDATE_BATCH",

  // PICKUP
  PICKUP_LIST: "PICKUP_LIST",
  CREATE_PICKUP: "CREATE_PICKUP",
  UPDATE_PICKUP: "UPDATE_PICKUP",
  DELETE_PICKUP: "DELETE_PICKUP",

  // PREP
  PREP_LIST: "PREP_LIST",
  CREATE_PREP: "CREATE_PREP",
  DELETE_PREP: "DELETE_PREP",
  UPDATE_PREP: "UPDATE_PREP",

  // NAVBAR
  ACTIVE_MENU: "ACTIVE_MENU",
  SCREEN_SIZE: "SCREEN_SIZE",
  ACTIVE_NOTIFICATION: "ACTIVE_NOTIFICATION",
  ACTIVE_USER_PROFILE: "ACTIVE_USER_PROFILE",
  IS_CLICKED: "IS_CLICKED",
};
