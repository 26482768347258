import axios from 'axios'
import { USPS_API } from '@config/siteConfig.js'
import { XMLParser, XMLBuilder } from 'fast-xml-parser'

const RatesProvider = {
    Buybox: function (data) {
        let price = 0;
        let weight = data?.package_weight;
        let cons = data?.pick_type;
        let qty = data?.package_quantity;
        if (qty == 0 || qty == null || qty == undefined) {
            qty = 1;
        }
        if ((data?.country).toLowerCase() === "ca" || (data?.country).toLowerCase() === "canada") {
            
            if (weight === "0lbs to 9.99lbs" || parseFloat(weight) <= 9.99) {
                price = 1.99;
            } else if (weight === "10lbs to 19.99lbs" || parseFloat(weight) >= 10.00 && parseFloat(weight) <= 19.99) {
                price = 3.99;
            } else if (weight === "20lbs to 29.99lbs" || parseFloat(weight) >= 20.00 && parseFloat(weight) <= 29.99) {
                price = 5.99;
            } else if (weight === "30lbs to 39.99lbs" || parseFloat(weight) >= 30.00 && parseFloat(weight) <= 39.99) {
                price = 7.99;
            } else if (weight === "Over 40lbs" || parseFloat(weight) >= 40.00) {
                price = 10.99;
            }

            let totalPrice = qty * price;
            return { totalPrice, price };
        }
        else {
            if (cons == "Consolidated") {
                price = 3.99;
            } else {
                price = 5.99;
            }

            let totalPrice = qty * price;
            let tax = 0;
            let postage_rate = 0;

            return { totalPrice, tax, postage_rate };
        }

    },
    USPS: async function (data) {
        var price, tax, postage_rate = 0;
        let ServiceType = data?.package_type_option.toUpperCase();
        let weight = data?.package_weight;
        let cons = data?.pick_type;
        let zip = data?.postal_code;
        let xmldata = `<RateV4Request USERID="270BUYBO5441"><Revision>2</Revision><Package ID="0"><Service>ALL</Service><ZipOrigination>${zip}</ZipOrigination><ZipDestination>${zip}</ZipDestination><Pounds>${weight}</Pounds><Ounces>2</Ounces><Container></Container><Width></Width><Length></Length><Height></Height><Girth></Girth><Machinable>TRUE</Machinable></Package></RateV4Request>`;
        let response = await axios.get(USPS_API + '?API=RateV4&XML=' + xmldata, {
            headers: {
                'Content-Type': 'application/xml'
            }
        }).then(response => response.data).catch(error => error);
        const parser = new XMLParser();
        // const builder = new XMLBuilder();
        let Obj = parser.parse(response)?.RateV4Response;
        // console.log(Obj)
        price = Obj?.Package?.Postage?.Rate + 0.10;
        return Obj;
    }
}

function dutyTaxesEstimator(state, category, product, made, price) {
    let Duty = 0;
    let Tax = 0;
    if(price > 3300) {
        return "ERROR: value of imported goods cannot exceed $3300!";
    }
    switch(state) {
        case "Ontario":
            switch(category) {
                case "Appliances":
                    switch(product) {
                        case "Kitchen appliances (blender, bread maker, coffee maker, food processor, toaster, kettle, slow cooker)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.09;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.09;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Large appliances (washer, dryer, refrigerator, dishwasher)":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.08;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.08;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Vacuums":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.08;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.08;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        default:
                            break;
                    }
                    break;
                case "Apparel":
                    switch(product) {
                        case "Accessories (hats, sunglasses)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.16;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.16;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Boys' clothing (bottoms, shirts, tops, outerwear, sleepwear)":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Girls' clothing (bottoms, shirts, tops, outerwear, sleepwear)":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Gloves":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Tax = price * 0.13;
                                        Tax = 0;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Ladies' clothing (pants, tops, dresses, socks, underwear, sleepwear)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Luggage (backpacks, suitcases, carry-ons)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.11;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.11;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Mens' clothing (pants, jeans, shirts, t-shirts, briefs, socks, sleepwear)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Shoes (athletic, casual boots, sandals, slippers, skates)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.20;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.20;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Appliances":
                    switch(product){
                        case "Kitchen appliances (blender, bread maker, coffee maker, food processor, toaster, kettle, slow cooker)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.9;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.9;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Large appliances (washer, dryer, refrigerator, dishwasher)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.8;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.8;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Vacuums":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.8;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.8;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;    
                    }
                    break;
                case "Automotive":
                    switch(product) {
                        case "Automobile engines":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 20) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 21 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Automotive parts":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.085;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.085;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Brakes and brake linings":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.10;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.10;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Tires":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.07;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.07;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Wipers":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        }
                    break;
                case "Baby Items":
                    switch(product) {
                        case "Baby bottles, nipples, pacifiers":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.065;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.065;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Baby cereal":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.095;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.095;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Baby clothing (creeper set, tops, tunics, pants)":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Baby monitors":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Car seats":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.06;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.06;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Carriage/Stroller":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.08;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.08;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Crib":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Diapers":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Formula":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "High chairs":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Electronics & Media":
                    switch(product) {
                        case "Batteries":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.07;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.07;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Cameras":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.05;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.05;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Computer software":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Computers":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "DVD / Blu-ray players":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Phones":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Pre-recorded DVDs, CDs, VHS tapes":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 20) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Printers":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Tablets, e-readers":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Televisions":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.05;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.05;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Video console":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Video games":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Home Furnishings":
                    switch(product) {
                        case "Bathroom: Towels, shower curtains":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.18;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Bedroom: Duvets, pillows, sheets, blankets":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Furniture: Bedroom, home entertainment, living room, desk, dining room":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.095;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.095;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Kitchen: Cookware, dishes, utensils":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.07;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.07;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Food":
                    switch(product) {
                        case "Bread":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.085;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.085;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Cereal":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.04;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.04;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Dairy: Cheese, milk, ice cream, yogurt":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 3.135;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 3.135;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Fruit":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.125;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.125;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Meat: Beef":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.265;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.265;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Meat: Pork":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Meat: Poultry":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 20) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 21 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Prepared meals":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 20) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 21 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Vegetables":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.105;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.105;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Health & Wellness":
                    switch(product) {
                        case "Beauty or makeup preparations":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.065;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.065;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Shampoo":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.065;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.065;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Soap":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.065;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.065;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Vitamins":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.105;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.105;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Jewellery":
                    switch(product) {
                        case "Costume jewellery":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.085;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.085;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Precious and semi precious":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.085;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.085;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Watches":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.05;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.05;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        default:
                            break;
                    }
                    break;
                case "Movies, Music, Books":
                    switch(product) {
                        case "Books":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Movies, TV programs (DVDs)":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Music (CDs)":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        default:
                            break;
                    }
                    break;
                case "Outdoor Living":
                    switch(product) {
                        case "BBQ":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.08;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.08;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Garden handheld tools":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.11;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.11;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Garden sheds & storage":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.065;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.065;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Gazebos":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 20) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.065;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.065;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Patio sets":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Power tools":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Sports":
                    switch(product) {
                        case "Bicycles":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.13;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.13;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Camping (tents, sleeping bags)":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.18;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Canoes":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = price * 0.095;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = price * 0.095;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Exercise equipment (treadmills, exercise bikes, home gym)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Golf clubs":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Hockey stick":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Personal watercraft (jet ski)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.095;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Safety helmets, pumps, seat pad":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Toys":
                    switch(product) {
                        case "Action figures, animals & stuffed toys, dolls":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) { // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = 0;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Board games, puzzles":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            break;
                        case "Vehicle play sets":
                                switch(made) {
                                    case "Canada":
                                        // 3rd condtion of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                            Duty = 0;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    case "Elsewhere":
                                        // 2nd condition of Rule of Reference
                                        if(price >= 0 && price <= 40) {
                                            Tax = 0;
                                            Duty = 0;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 41 && price <= 150) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        else if(price >= 151 && price <= 3300) {
                                            Duty = 0;
                                            price = price + Duty;
                                            Tax = price * 0.13;
                                            price = price + Tax;
                                            return { price, Duty, Tax };
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                        case "Wheeled toys (tricycles & pedal cars)":
                            switch(made) {
                                case "Canada":
                                    // 3rd condtion of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {  // 4th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {  // 5th condition of Rule of Reference
                                        Duty = 0;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                case "Elsewhere":
                                    // 2nd condition of Rule of Reference
                                    if(price >= 0 && price <= 40) {
                                        Tax = 0;
                                        Duty = 0;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 41 && price <= 150) {
                                        Duty = price * 0.08;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    else if(price >= 151 && price <= 3300) {
                                        Duty = price * 0.08;
                                        price = price + Duty;
                                        Tax = price * 0.13;
                                        price = price + Tax;
                                        return { price, Duty, Tax };
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
        }
}

export { RatesProvider, dutyTaxesEstimator }