import React,{useContext,createContext,useState} from 'react'
import ScreenModal from "@components/ScreenModal";
import CreateShipment from '@pages/Shipments/createShipment'
import CreatePickup from '@pages/Pickups/createPickup'
import EditShipment from '@pages/Shipments/editShipment'

 const screenModalContext = createContext('');

function PopupHandler({children}) {
  const [open, setOpen] = useState(false);
  const [popupType,setPopupType] = useState('')
  const [activeShipment, setActiveShipment] = useState(null)

  const modalHandler = (val, type, state) => {
    setOpen(val);
    setPopupType(type)
    Array.isArray(state) ? setActiveShipment(state[0]) : setActiveShipment(state)
  }
  const closeModal = () => { setOpen(false)
  setPopupType('');
  }
const Forms = {
'shipment':<CreateShipment/>,
'pickup':<CreatePickup/>,
'editShipment': <EditShipment activeShipment={activeShipment}/>
}

  return (
    <screenModalContext.Provider value={{modalHandler,closeModal}}>
      {children}
    <ScreenModal Show={open}>
   {Forms[popupType]}
    </ScreenModal>
  </screenModalContext.Provider>
  )
}

const usePopupHandler = ()=> useContext(screenModalContext)

export default PopupHandler
export {usePopupHandler}