import { combineReducers } from 'redux';
import Auth from './auth/reducer';
import Shipment from './shipment/reducer';
import Prep from './prep/reducer';
import Pickup from './pickup/reducer';
import Batches from './batches/reducer';
import { activeMenuReducer, screenSizeReducer, isClickedReducer } from './navbar/reducer';

export default combineReducers({
   Auth, 
   Shipment,
   Prep,
   Batches,
   Pickup,
   activeMenu: activeMenuReducer,
   screenSize: screenSizeReducer,
   isClicked: isClickedReducer,
  });