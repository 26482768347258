import axios from 'axios'
import { USPS_API } from '@config/siteConfig.js'
import { XMLParser, XMLBuilder } from 'fast-xml-parser'
import Service from '@helpers/GeoData'
import Swal from "sweetalert2";

const LabelProvider = {

    USPS: async function (data, user_details) {
        if (data.postage_type && user_details) {
            let fromCountrySet = Service?.filter(countrySet => user_details.country == countrySet.name)[0]
            let stateCode = fromCountrySet?.states?.filter(state => state.name == user_details.province)[0].state_code;
            data["barcode_required"] = parseInt(data.postal_code)
            let ServiceType = "";
            var price, tax, postage = 0;
            if (data.postage_type.includes('-')) {
                ServiceType = data?.postage_type.split('-')[1];
            }
            else {
                ServiceType = data?.postage_type;
            }
            let weight = data?.package_weight;
            let xmldata = `<eVSRequest USERID="270BUYBO5441"><Option></Option><Revision>3</Revision><ImageParameters><ImageParameter>4x6LABELP</ImageParameter></ImageParameters><FromName>${user_details?.full_name}</FromName><FromFirm></FromFirm><FromAddress1></FromAddress1><FromAddress2>${user_details?.address_line1}</FromAddress2><FromCity>${user_details?.city}</FromCity><FromState>${stateCode}</FromState><FromZip5>${user_details?.postal_code}</FromZip5><FromZip4></FromZip4><FromPhone>${user_details?.phone}</FromPhone><POZipCode>92660</POZipCode><AllowNonCleansedOriginAddr>TRUE</AllowNonCleansedOriginAddr><ToName>${data?.name}</ToName><ToFirm></ToFirm><ToAddress1></ToAddress1><ToAddress2>${data?.address1}</ToAddress2><ToCity>${data?.city}</ToCity><ToState>${data?.province}</ToState><ToZip5>${data?.postal_code}</ToZip5><ToZip4></ToZip4><ToPhone>${data?.phone}</ToPhone><AllowNonCleansedDestAddr>False</AllowNonCleansedDestAddr><WeightInOunces>${data?.package_weight}</WeightInOunces><ServiceType>${ServiceType}</ServiceType><Container>VARIABLE </Container><Width>${data?.package_width}</Width><Length>${data?.package_length}</Length><Height>${data?.package_height}</Height><Machinable>True</Machinable><CustomerRefNo>123456789</CustomerRefNo><ExtraServices><ExtraService></ExtraService></ExtraServices><ReceiptOption>None</ReceiptOption><ImageType>PDF</ImageType><HoldForManifest>Y</HoldForManifest><PrintCustomerRefNo>false</PrintCustomerRefNo></eVSRequest>`;
            let response = await axios.get(USPS_API + '?API=eVS&XML=' + xmldata, {
                headers: {
                    'Content-Type': 'application/xml'
                }
            }).then(response =>
                response.data
            ).catch(error =>
                error
            );

            const parser = new XMLParser();
            var Obj = parser.parse(response);
            
                if(Obj.Error){
                    alert(Obj.Error.Description.split(":")[0],"\n",Obj.Error.Description.split(":")[1])
                }else{
                    if (data?.barcodeRequired) {
                        return Obj?.eVSResponse?.BarcodeNumber
                    }
                    var winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
                    'resizable,screenX=50,screenY=50,width=850,height=1050';
                    var htmlPop = '<embed width=100% height=100%'
                    + ' type="application/pdf"'
                    + ' src="data:application/pdf;base64,'
                    + Obj.eVSResponse?.LabelImage
                    + '"></embed>';
                    var printWindow = window.open("", "PDF", winparams);
                    printWindow.document.write(htmlPop);
                    var parsers = new DOMParser()
                    var trackingNumber = parsers.parseFromString(response, "text/xml").querySelector("TrackingNumber").textContent;
                    return trackingNumber;
                }

            }
    }
}

export { LabelProvider }