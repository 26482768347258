
import React from 'react'

// COMPONENTS IMPORTS //
import { Input, Form, Select, Typography } from 'antd';
import InputNumber from '@components/InputNumber';

const PackageDetails = ({ form }) => {
  // Extracted Data
  let Country = form.getFieldValue('country');
  let Province = form.getFieldValue('province');
  let City = form.getFieldValue('city');
  let PostalCode = form.getFieldValue('postal_code');
  let Address1 = form.getFieldValue('address1');
  let Address2 = form.getFieldValue('address2');
  let is_crossborder = form.getFieldValue('is_cross_border')
  let has_postage = form.getFieldValue('has_postage');

  const validateRetailValue = (rule, value, callback) => {
    const intValue = parseInt(value, 10);
    if (isNaN(intValue) || intValue < 0 || intValue > 9999) {
      callback('Retail Value must be between 0 and 9999');
    } else {
      callback();
    }
  };

  return (
    <div className="grid">
      <Typography.Title level={3}>Package Info</Typography.Title>
      <div className="grid-flow-row">
          <Form.Item name="package_description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea placeholder='Package Description'/>
            </Form.Item></div>
      <div className="grid gap-2 grid-cols-2">    
        <div>
          <Form.Item name="retail_value"
            rules={[
              {
                required: true,
                validator: validateRetailValue,
              },
            ]}
          >
            <Input
              type='number'
              size="large"
              maxLength={9999}
              max={9999}
              min={0}
            placeholder='Retail Value' suffix={'$'} />
          </Form.Item>

        </div>
        </div>
        {Country!=="United States"?
        <div className="grid-flow-row">

          <Form.Item name="pick_type"
          >
            <Select
              size="large"
              placeholder="Pickup Types"

              options={[
                {
                  value: 'consolidated',
                  label: 'Consolidated',
                },
                {
                  value: 'unconsolidated',
                  label: 'Unconsolidated',
                },

              ]}
            />
          </Form.Item>
        </div>:''}
      <div className="grid-flow-row">
        <h1 className="text-2xl text-gray-600 my-3 font-bold">Store Info</h1>
      </div>
      <div className="grid gap-2 grid-cols-2">
        <div>
          <Form.Item name="order_id"
          >
            <Input
              size="large"
              placeholder='Order ID (Optional)' />
          </Form.Item>
        </div>


      </div>
    </div>
  )
}

export default PackageDetails