import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from "react-redux";
import { RatesProvider } from '@services/Pricing'
import { LabelProvider } from '@services/LabelPrinting'
import { Button } from 'antd';
import { Barcode } from 'react-barcode';
import jsPDF from 'jspdf';

const Summary = ({ form }) => {
  const [tax, setTax] = useState(0);
  const [postage, setPostage] = useState(0);
  const Auth = useSelector((state) => state.Auth?.auth);
  let printLable = null;
  let isPrintable = false;
  // Extracted Data
  let Data = form.getFieldsValue(true);
  if (Data?.barcodeRequired) delete Data.barcodeRequired;
  let dimensions = null;
  if (Data?.package_height && Data?.package_length && Data?.package_width) {
    dimensions = Data?.package_height + ' x ' + Data?.package_length + ' x ' + Data?.package_width;
  }
  // Profile Data
  let Shipper = Auth?.user?.account;
  // Label Print Handler
  if (typeof Data?.postage_type !== "object") {
    if (Data?.postage_type){
      if (Data?.postage_type.includes('USPS')) {
        printLable = LabelProvider["USPS"];
        isPrintable = true;
      }
    }
    else{
      isPrintable = false;
    }
  }

  return (
    <>
      {Data &&
        <div className="grid">
          <div className="grid gap-2 grid-cols-2">
            <div className='pb-4'>
              <h1 className="text-xl text-gray-600 font-bold text-center pb-4 border-b-2">From Address</h1>
              <p className="text-left  pt-2 text-center">{Shipper?.address_line1}</p>
              <p>{Shipper?.province + ',' + Shipper?.city},</p><p>{Shipper?.postal_code},{Shipper?.country}</p>
            </div>
            <div className='pb-4'>
              <h1 className="text-xl text-gray-600 font-bold text-center pb-4 border-b-2">To Address</h1>
              <p className="text-left  pt-2 text-center">{Data?.address1
              }</p><p>{Data?.province},{Data?.city},</p><p>{Data?.postal_code},{Data?.country}</p>

            </div>
          </div>
          <div className="grid gap-2 grid-cols-2">
            <div className="px-2">
              <h1 className="text-xl text-gray-600 font-bold text-center pb-4 border-b-2">Shipment Details</h1>
              {Data?.package_weight > 0 &&
                <div className="flex items-center justify-between pb-4">
                  <p className="font-bold">Weight</p>
                  <p>{Data?.package_weight ? Data?.package_weight : 0} lbs</p>
                </div>}
              {Data?.retail_value > 0 &&
                <div className="flex items-center justify-between pb-4">
                  <p className="font-bold">Product Value</p>
                  <p>${Data?.retail_value > 0 ? Data?.retail_value : 0}</p>
                </div>}
              {dimensions &&
                <div className="flex items-center justify-between pb-4">
                  <p className="font-bold">Dimensions</p>
                  <p>{dimensions}</p>
                </div>}
              {dimensions &&
                <div className="flex items-center justify-between pb-4">
                  <p className="font-bold">Unit</p>
                  <p>{Data?.package_size_unit}</p>
                </div>}

            </div>

            <div className="px-2">
              <h1 className="text-xl text-gray-600 font-bold text-center pb-4 border-b-2">Estimated Costs (CAD)</h1>
              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Post By</p>
                <p>{Data?.package_type}</p>
              </div>
              {postage > 0 && (
                <div className="flex items-center justify-between pb-4">
                  <p className="font-bold">Postage Rate</p>
                  <p>${postage}</p>
                </div>)}
              {tax > 0 && (
                <div className="flex items-center justify-between pb-4">
                  <p className="font-bold">Duty & Tax (3%)</p>
                  <p>${tax}</p>
                </div>)}

              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Total</p>
                <p>${(Data["shipment_amount"]).toFixed(2)}</p>
              </div>

            </div>
          </div>
          {isPrintable &&
            <div className='grid py-6'>
              <div className='w-2/5 mx-auto'>
                <Button type="primary" onClick={() => printLable(Data, Auth?.user?.account)}>Generate USPS Label</Button>
              </div>
            </div>}
        </div>}
    </>
  )
}

export default Summary