
import React from 'react'

// COMPONENTS IMPORTS //
import { Input, Form, Select,DatePicker, Typography } from 'antd';
import moment from 'moment';
const PackageDetails = ({ form }) => {


  return (
    <div className="grid">
      <Typography.Title level={3}>Package Info</Typography.Title>
      {/* Row 1 */}
      <div className="grid-flow-row">
        <Form.Item
          name="package_content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Name" size="large" />
        </Form.Item>
      </div>
      <div className="grid-flow-row">

          <Form.Item name="instruction"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea placeholder='Pickup Instruction'/>
            </Form.Item></div>
      <div className="grid gap-2 grid-cols-3"> 
     
          <Form.Item name="pickup_date"
            rules={[
              {
                required: true,
              },
            ]}
          >
           <DatePicker  format={'YYYY-MM-DD'} size='large' className='w-full' picker='date' />
          </Form.Item>
          <Form.Item name="pickup_time"
            rules={[
              {
                required: true,
              },
            ]}
          >
           <DatePicker size='large' className='w-full' picker='time' />
          </Form.Item>

            <Form.Item name="package_weight"

              rules={[
                {
                  required: true,
                  max: 99,
                },
              ]}
            >
              <Input
                type='number'
                size="large"
                placeholder='Package Weight'
                maxLength={99}
                max={99}
                min={1}
                suffix={'lbs'} />
            </Form.Item>
       
        </div>
    
      <div className="grid-flow-row">
        <h1 className="text-2xl text-gray-600 my-3 font-bold">Dimensions</h1>
      </div>
      <div className="grid grid-cols-4 gap-4">

<div className='custom-wrapper-width'>
  <Form.Item
    name="package_length"

  >
    <Input placeholder="Length" size="large" />
  </Form.Item>

</div>
<div className='custom-wrapper-width'>
  <Form.Item
    name="package_width"

  >
    <Input placeholder="Width" size="large" />
  </Form.Item>

</div>
<div className='custom-wrapper-width'>
  <Form.Item
    name="package_height"
  >
    <Input placeholder="Height" size="large" />
  </Form.Item>

</div>
<div className='custom-wrapper-width'>
  <Form.Item name="package_dimensions_unit"

  >
    <Select
      placeholder="Select Unit"
      size="large"
      showSearch
    
      options={[
        {
          value: "in",
          label: "Inch"
        },
        {
          value: "cm",
          label: "cm"
        }]}


    />


  </Form.Item>
</div>
</div>
    </div>
  )
}

export default PackageDetails