import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "@config/siteConfig";

// import qs from "qs";

const _api = {

  get_batches: async function (token) {
    return axios
      .get(API_URL + "batch/", {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },

  get_batch: async function (token,id) {
    return axios
      .get(API_URL + "batch/?batch_id=" + id, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },

  update_batch: async function (id,token, updatedName) {
    // Create a payload object with the updated batch name
    const payload = {
      batch_name: updatedName,
      id: id,
    };
  
    return axios
      .put(API_URL + "batch/", payload, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },


  create_batch: async function (data,token) {
    return axios
      .post(API_URL + "batch/", data, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  delete_batch: async function (token,id) {
    return axios
      .delete(API_URL + "batch/?id="+id, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  

 
};
export default _api;
