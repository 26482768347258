import axios from "axios";
import { API_URL } from "@config/siteConfig";

const _api = {

  LoginUser: async function (data) {
    return axios
      .post(API_URL + "login/", data, {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },

  RegisterUser: async function (data) {
    localStorage.setItem("email", '');
    localStorage.setItem("email", data.email);
    return axios
      .post(API_URL + "register/", data, {
        headers: { 
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
      },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return { error: "response error", error_description: error?.response.data };
      });
  },

  Account_setup: async function (data, token) {
    return axios
      .post(API_URL + "user-account-setup/", data, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return { error: "response error", error_description: error?.response.data };
      });
  },

  verify_user: async function (token, userId) {
    return axios
      .get(API_URL + "verify-user/?token=" + token + "&user_id=" + userId, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return { error: "response error", error_description: error?.response.data };
      });
  },

  user_agreement: async function (data, token) {
    token = data.token
    return axios.put(API_URL + "terms-and-condition/", data, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return { error: "response error", error_description: error?.response.data };
      });
  },

  resend_email: async function (token) {
    let userEmail = localStorage.getItem("email");
    return axios.post(API_URL + `resend-verification/`,userEmail, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          // Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return { error: "response error", error_description: error?.response.data };
      });
  },
};
export default _api;
