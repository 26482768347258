import { _types } from "../types";

const { STATE, CLEAR, FAILD, SUCCESS, CREATE_PREP, PREP_LIST, DELETE_PREP, UPDATE_PREP } = _types;
const initState = {
  isProgress: false,
  prep_item: null,
  prep_list: null,
  isError: false,
  errorText: "",
  isSuccess: false,
  successText: "",
  response_type: '',
};
function prepReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        isProgress: true,
      };
    case PREP_LIST + SUCCESS:
      return {
        ...state,
        prep_list: action.payload
      };

    case CREATE_PREP + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        successText: action.payload,
        response_type: 'create'
      };
    case CREATE_PREP + FAILD:
      return {
        ...state,
        isError: true,
        errorText: action.payload
      };

    case DELETE_PREP + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        successText: "Prep deleted Successfully",
        response_type: 'delete'
      };

    case UPDATE_PREP + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        successText: "Selected prep item is moved to packed item",
        response_type: 'delete'
      };
    
    case UPDATE_PREP + FAILD:
      return {
        ...state,
        isSuccess: true,
        successText: action.payload,
        response_type: 'update'
      };

    case STATE + CLEAR:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        isSuccess: false,
        successText: "",
        response_type: "",
      };

    default:
      return state;
  }
}

export default prepReducer;
