import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { REQ, CLEAR, STATE, SUCCESS, FAILD, BATCH_LIST, CREATE_BATCH, BATCH, DELETE_BATCH, UPDATE_BATCH } = _types;

export function* batchSaga() {

  // Batches List
  yield takeLatest(BATCH_LIST + REQ, function* ({ payload }) {
    const response = yield _api.get_batches(payload);
    if (response?.error) {
      yield put({ type: BATCH_LIST + FAILD, payload: "Some thing Went Wrong" });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: BATCH_LIST + SUCCESS,
        payload: response?.data,
      });
    }
  });


  // Batch
  yield takeLatest(BATCH + REQ, function* ({ payload }) {
    const response = yield _api.get_batch(payload.token, payload.id);
    if (!response?.error) {
      yield put({
        type: BATCH + SUCCESS,
        payload: response?.data,
      });
    }
  });


  // Create Batch
  yield takeLatest(CREATE_BATCH + REQ, function* ({ payload }) {
    const response = yield _api.create_batch(payload.data, payload.token);
    if (response?.error) {
      yield put({
        type: CREATE_BATCH + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

  // Update Batch
  yield takeLatest(UPDATE_BATCH + REQ, function* ({ payload }) {
    const response = yield _api.update_batch(payload.id, payload.token, payload.name);
    if (response?.error) {
      yield put({
        type: UPDATE_BATCH + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

  // Delete Batch
  yield takeLatest(DELETE_BATCH + REQ, function* ({ payload }) {
    const response = yield _api.delete_batch(payload.token, payload.id);
    if (!response?.error) {
      yield put({
        type: DELETE_BATCH + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(batchSaga)]);
}
