
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import shipmentSagas from './shipment/saga';
import batchSagas from './batches/saga';
import prepSagas from './prep/saga';
import pickupSagas from './pickup/saga';
export default function* rootSaga() {
  yield all([
    authSagas(),
    shipmentSagas(),
    prepSagas(),
    batchSagas(),
    pickupSagas()
  ]);
  }