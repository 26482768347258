import { Input, Tooltip } from 'antd';
const formatNumber = (value) => new Intl.NumberFormat().format(value);
const NumericInput = (props) => {
  const { value, onChange } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;

    const reg = /^(0?[0-9]|[1-9][0-9])(\.\d{1,2})?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
        if(inputValue.length<=props.max){
      onChange(inputValue);
        }
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-' ) {
      valueTemp = value.slice(0, -1);
    }
    if(valueTemp.length<=props.max){
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    }
  };
  const title = 'Input a number';
  return (
    <Tooltip trigger={props.tooltip?['focus']:''} title={title} placement="topLeft" overlayClassName="numeric-input">
      <Input
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={99}
        max={99}
      />
    </Tooltip>
  );
};


export default NumericInput;