import React, { useState } from 'react'

// COMPONENTS IMPORTS //
import { Input, Form, Select, Radio, Space, Typography } from 'antd';
import InputNumber from '@components/InputNumber';

const services = {
  Buybox:
    [{ value: 'Letter', label: "Letter" },
    { value: 'Large Envelope or Flat', label: "Large Envelope or Flat" },
    { value: 'Thick Envelope', label: "Thick Envelope" },
    { value: 'Parcel', label: "Parcel" }
    ],
  USPS:
    [
      { value: "Legal Flat Rate Envelope", label: "Legal Flat Rate Envelope" },
      { value: "Flat Rate Padded Envelope", label: "Flat Rate Padded Envelope" },
      { value: "Small Flat Rate Box", label: "Small Flat Rate Box" },
      { value: "Medium Flat Rate Box", label: "Medium Flat Rate Box" },
      { value: "Large Flat Rate Box", label: "Large Flat Rate Box" },
      { value: "Regional Rate Box A1", label: "Regional Rate Box A1" },
      { value: "Regional Rate Box A2", label: "Regional Rate Box A2" },
      { value: "Regional Rate Box B1", label: "Regional Rate Box B1" },
      { value: "Regional Rate Box B2", label: "Regional Rate Box B2" },
      { value: "Flat Rate Envelope", label: "Flat Rate Envelope" },
    ],
}

const PackageType = ({ form }) => {
  const [currentPostby, setCurrentPost] = useState(form.getFieldValue('package_type'))
  const [isDimension, setIsDimension] = useState(false)
  const [dimensionSize, setDimensionSize] = useState(3)
  // Extracted Data
  let Country = form.getFieldValue('country');
  let Province = form.getFieldValue('province');
  let City = form.getFieldValue('city');
  let PostalCode = form.getFieldValue('postal_code');
  let Address1 = form.getFieldValue('address1');
  let Address2 = form.getFieldValue('address2');
  let is_crossborder = form.getFieldValue('is_cross_border')
  let has_postage = form.getFieldValue('has_postage');

  // Handling Tabs
  function tabsHandler (e) {
    setCurrentPost(e.target.value)
    form.setFieldValue('package_length', "")
    form.setFieldValue('package_width', "")
    form.setFieldValue('package_height', "")
  }

  // Handling Dimensions
  function dimensionHandler (e) {
    switch (e.target.value) {
      case "Thick Envelope" || "Parcel":
        setIsDimension(true)
        setDimensionSize(3)
        form.setFieldValue('package_length', "")
        form.setFieldValue('package_width', "")
        form.setFieldValue('package_height', "")
        break

      case "Legal Flat Rate Envelope":
        setDimensionSize(2)
        form.setFieldValue('package_width', "15")
        form.setFieldValue('package_height', "9.5")
        break

      case "Flat Rate Envelope":
        setDimensionSize(2)
        form.setFieldValue('package_width', "12.5")
        form.setFieldValue('package_height', "9.5")
        break
      
      case "Flat Rate Padded Envelope":
        setDimensionSize(2)
        form.setFieldValue('package_width', "9.5")
        form.setFieldValue('package_height', "12.5")
        break

      case "Small Flat Rate Box":
        setDimensionSize(3)
        form.setFieldValue('package_length', "8.625")
        form.setFieldValue('package_width', "1.625")
        form.setFieldValue('package_height', "5.375")
        break

      case "Medium Flat Rate Box":
        setDimensionSize(3)
        form.setFieldValue('package_length', "11")
        form.setFieldValue('package_width', "8.5")
        form.setFieldValue('package_height', "5.5")
        break

      case "Large Flat Rate Box":
        setDimensionSize(3)
        form.setFieldValue('package_length', "12")
        form.setFieldValue('package_width', "12")
        form.setFieldValue('package_height', "5.75")
        break

      case "Regional Rate Box A1":
        setDimensionSize(3)
        form.setFieldValue('package_length', "9.87")
        form.setFieldValue('package_width', "6.87")
        form.setFieldValue('package_height', "5")
        break

      case "Regional Rate Box A2":
        setDimensionSize(3)
        form.setFieldValue('package_length', "12.93")
        form.setFieldValue('package_width', "10.93")
        form.setFieldValue('package_height', "1.5")
        break

      case "Regional Rate Box B1":
        setDimensionSize(3)
        form.setFieldValue('package_length', "11.75")
        form.setFieldValue('package_width', "9.5")
        form.setFieldValue('package_height', "4.5")
        break

      case "Regional Rate Box B2":
        setDimensionSize(3)
        setUspsValue({length: "15", width: "12", height: "3"})
        form.setFieldValue('package_length', "15")
        form.setFieldValue('package_width', "12")
        form.setFieldValue('package_height', "3")
        break

      default:
        setIsDimension(false)
        setDimensionSize(3)
        form.setFieldValue('package_length', "")
        form.setFieldValue('package_width', "")
        form.setFieldValue('package_height', "")
        break
    }
  }

  return (
    <div className='grid'>
      <Typography.Title level={3}>Package Details</Typography.Title>
      <div className='grid grid-rows-1'>
        <Form.Item name="package_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group defaultValue="Buybox" className='custom-tabs-radio' onChange={tabsHandler} buttonStyle="solid">
            <Radio.Button value="Buybox">Own Packaging</Radio.Button>
            <Radio.Button value="USPS">USPS Provided</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className='grid grid-cols-1 gap-4'>
        <div className="shadow-sm border-2 border-r-4 max-h-[200px] h-full overflow-y-scroll text-left p-6 flex ">
          <Form.Item
            name="package_type_option"    
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group onChange={dimensionHandler}>
              <Space direction="vertical" >
                {services[currentPostby].map((item, k) => (
                  <Radio key={k} value={item.value}>{item.label}</Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>

      <div className="grid grid-cols-1">
        <h1 className="text-2xl text-gray-600 my-3 font-bold">Dimensions</h1>
      </div>
      <div className={`grid grid-cols-${dimensionSize == 3 ? 4 : 3} gap-4`}>

        {dimensionSize == 3 && <div className='custom-wrapper-width'>
          <Form.Item
            name="package_length"
            rules={[
              {
                required: isDimension,
              },
            ]}
          >
            <InputNumber
              type="number"
              maxLength={4}
              max={4}
              min={1}
              tooltip={false}
              disabled={currentPostby === "USPS" ? true : false}
            placeholder="Length" size="large" />
          </Form.Item>
        </div>}

        <div className='custom-wrapper-width'>
          <Form.Item
            name="package_width"
            rules={[
              {
                required: isDimension,

              },
            ]}
          >
            <InputNumber
              type="number"
              maxLength={4}
              max={4}
              min={1}
              tooltip={false}
              disabled={currentPostby === "USPS" ? true : false}
            placeholder="Width" size="large" />
          </Form.Item>
        </div>

        <div className='custom-wrapper-width'>
          <Form.Item
            name="package_height"
            rules={[
              {
                required: isDimension,

              },
            ]}
          >
            <InputNumber
              type="number"
              maxLength={4}
              max={4}
              min={1}
              tooltip={false}
              disabled={currentPostby === "USPS" ? true : false}
            placeholder="Height" size="large" />
          </Form.Item>

        </div>

        <div className='custom-wrapper-width'>
          <Form.Item name="package_size_unit"
          >
            <Select
              placeholder="Select Unit"
              size="large"
              options={currentPostby === 'Buybox' ? [
                {
                  value: "in",
                  label: "in"
                },
                {
                  value: "cm",
                  label: "cm"
                }
              ] : [
                {
                  value: "in",
                  label: "in"
                }
              ]}
            />
          </Form.Item>
        </div>
      </div>
        <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
        <h1 className="text-2xl text-gray-600 my-3 font-bold">Package Weight</h1>
        <div>
            <Form.Item name="package_weight"

              rules={[
                {
                  required: true,
             
                },
              ]}
            >
              <InputNumber
                type='number'
                size="large"
                placeholder='Package Weight'
                maxLength={4}
                max={4}
                min={1}
                tooltip={true}
                suffix={'lbs'} />
            </Form.Item>
          </div>
      </div>
    </div>
  )
}

export default PackageType