import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";
import { Input, Form, Select, Typography, Button, Steps } from 'antd';
import moment from 'moment';
import Location from './forms/Pick_Location'
import Contents from './forms/Contents'
import Summary from './forms/Summary'

import { useSelector, useDispatch } from 'react-redux';
import Pickup_Action from '@redux/pickup/actions';
import LoadingScreen from "@components/LoadingScreen";
import { usePopupHandler } from '@layouts/PopupHandler'

const renderStepContent = (step, form, setAddressValid) => {
  switch (step) {
    case 0:
      return <Location form={form} />
    case 1:
      return <Contents form={form} />
    case 2:
      return <Summary form={form} />
  }
}

const steps = ["Pickup Location", "Contents", "Summary"]

export default function ShipmentCreate() {
  const { closeModal } = usePopupHandler();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth?.auth);
  const Pickup = useSelector((state) => state?.Pickup);
  const [FormState, setFormState] = useState({
    name: "",
    address1: "",
    address2: "",
    city: null,
    province: null,
    postal_code: "",
    phone: "",
    retail_value: "",
    package_weight: "",
    package_length: "",
    package_width: "",
    package_height: "",
    package_size_unit: 'cm',
  })
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAddressValid, setAddressValid] = useState(false)

  const items = steps.map((item) => ({
    key: item,
    title: item,
  }));

  const next = () => {
    form
      .validateFields()
      .then((response) => {
        setActiveStep(activeStep + 1)
      })
      .catch((error) => console.log(error));
  };
  const prev = () => {
    setActiveStep(activeStep - 1)
  };

  const handleSubmit = () => {
    let data = { ...form.getFieldsValue(true), pickup_date: moment(form.getFieldValue('pickup_date')).format('YYYY-MM-DD'), pickup_time: moment(form.getFieldValue('pickup_date')).format('hh:mm:ss'), country: 'Canada' }
    dispatch(Pickup_Action.create_pickup({ data, token: Auth?.token }))
  }

  // Success Response Handler
  useEffect(() => {
    if (Pickup.isSuccess) {
      Swal.fire(
        'Successfull',
        Pickup?.successText,
        'success',
      )
      closeModal()
      form.resetFields();
    }
    else if (Pickup.isError && Pickup.response_type === 'edit') {
      Swal.fire(
        'error',
        Pickup?.errorText,
        'error',
      )
    }
  }, [Pickup.isSuccess, Pickup.isError])

  return (
    <>

      {isLoading && <LoadingScreen />}
      <div className='header py-4 px-4 bg-[#00443c]'>
        <div className='flex w-full justify-between'>
          <div>    <Typography.Title level={3}>
            <span className='text-white'>Create New Pickup</span>
          </Typography.Title></div>
          <div> <Button type='text' className='text-white' onClick={() => closeModal()} >
            Close
          </Button></div>
        </div>
      </div>
      <Form
        initialValues={FormState}
        form={form}
        layout="vertical"
      >

        <div className='content-center justify-center items-center contents min-h-screen'>
          <div className="shadow-lg p-8 mb-6">
            <Steps current={activeStep} items={items} size="small" />
          </div>
          <div className="shadow-xl p-6 mt-[9vw] flex flex-col items-center justify-center text-center border xs:w-[350px] sm:w-[550px] md:w-[800px] my-auto mx-auto">
            <div className="steps-content w-full">{renderStepContent(activeStep, form, setAddressValid)}</div>
            <div className="steps-action">

              {activeStep < steps.length - 1 && (
                <Button type="primary" disabled={activeStep == 1 ? isAddressValid : false} onClick={() => next()}>
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button type="primary" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              )}
              {activeStep > 0 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}
