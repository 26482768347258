import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "@config/siteConfig";

// import qs from "qs";

const _api = {
  get_preps_item: async function (token) {
    console.log("GET TOKEN\n"+token)
    return axios
      .get(API_URL + "prep-pack-ship/", {
        headers: {'Content-Type' : 'application/json; charset=utf-8',
        Authorization: 'Token '+ token
      },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        
        return error?.response.data;
      });
  },
  delete_prep: async function (token,id) {
    return axios
      .delete(API_URL + "prep-pack-ship/?id="+id, {
        headers: {'Content-Type' : 'application/json; charset=utf-8',
        Authorization: 'Token '+ token
      },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        
        return error?.response.data;
      });
  },
  update_prep: async function (token,payload) {
    return axios.patch(API_URL + "prep-pack-ship/",payload, {
        headers: {'Content-Type' : 'application/json; charset=utf-8',
        Accept: 'application/json',
        Authorization: 'Token '+ token
      },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        
        return error?.response.data;
      });
  },  

  create_prep: async function (data, token) {
    return axios
      .post(API_URL + "prep-pack-ship/", data, {
        headers: {'Content-Type' : 'application/json; charset=utf-8',
        Authorization: 'Token '+ token
      },
      })
      .then(function (response) {
        if(response.data.status!==200){
          Swal.fire(
            'Error!',
           response?.data?.message,
            'error',
          );
        }
        return response?.data;
      })
      .catch(function (error) {
        Swal.fire(
          'Error!',
         response?.data?.message,
          'error',
        );
        return error?.response.data;
      });
  },

 
};
export default _api;
