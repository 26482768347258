import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const {
  REQ,
  CLEAR,
  STATE,
  SUCCESS,
  FAILD,
  SHIPMENT_LIST,
  CREATE_SHIPMENT,
  IMPORT_SHIPMENT,
  IMPORTED_SHIPMENT_LIST,
  UPDATE_SHIPMENT,
  EDIT_SHIPMENT,
  DELETE_SHIPMENT
} = _types;

export function* shipmentSaga() {

  // Shipment List
  yield takeLatest(SHIPMENT_LIST + REQ, function* ({ payload }) {
    const response = yield _api.get_shipments(payload);
    if (response?.error) {
      yield put({ type: SHIPMENT_LIST + FAILD, payload: "Some thing Went Wrong" });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: SHIPMENT_LIST + SUCCESS,
        payload: response?.data,
      });
    }
  });

  // Imported Shipment List
  yield takeLatest(IMPORTED_SHIPMENT_LIST + REQ, function* ({ payload }) {
    const response = yield _api.get_imported_shipments(payload);
    if (response?.error) {
      yield put({ type: SHIPMENT_LIST + FAILD, payload: "Some thing Went Wrong" });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: IMPORTED_SHIPMENT_LIST + SUCCESS,
        payload: response?.data,
      });
    }
  });

  // Create Shipment
  yield takeLatest(CREATE_SHIPMENT + REQ, function* ({ payload }) {
    const response = yield _api.create_shipments(payload.data, payload.token);
    if (response.status < 300) {
      yield put({
        type: CREATE_SHIPMENT + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });

    } else {
      yield put({ type: CREATE_SHIPMENT + FAILD, payload: response.message });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

  // Update Shipment
  yield takeLatest(UPDATE_SHIPMENT + REQ, function* ({ payload }) {
    const response = yield _api.update_shipment(payload.data, payload.token);
    if (response?.error) {
      yield put({ type: UPDATE_SHIPMENT + FAILD, payload: "Some thing Went Wrong" });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: UPDATE_SHIPMENT + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

  // Edit Shipment
  yield takeLatest(EDIT_SHIPMENT + REQ, function* ({ payload }) {
    const response = yield _api.update_shipment(payload.data, payload.token);
    if (response?.error) {
      yield put({ type: EDIT_SHIPMENT + FAILD, payload: "Some thing Went Wrong" });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: EDIT_SHIPMENT + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

  // Import Shipment
  yield takeLatest(IMPORT_SHIPMENT + REQ, function* ({ payload }) {
    const response = yield _api.import_shipments(payload.data, payload.token);
    if (response?.error) {
      yield put({ type: IMPORT_SHIPMENT + FAILD, payload: "Some thing Went Wrong" });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: IMPORT_SHIPMENT + SUCCESS,
        payload: response?.data,
      });
    }
  });

  // Delete Shipment
  yield takeLatest(DELETE_SHIPMENT + REQ, function* ({ payload }) {
    const response = yield _api.delete_shipments(payload.id, payload.token);
    if (!response?.error) {
      yield put({
        type: DELETE_SHIPMENT + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(shipmentSaga)]);
}
