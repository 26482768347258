import { _types } from "../types";

const { STATE, CLEAR, PICKUP_LIST, SUCCESS, FAILD, CREATE_PICKUP } = _types;
const initState = {
  isProgress: false,
  isError: false,
  errorText: "",
  isSuccess: false,
  pickups: null,
  response_type:null
};
function pickupReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        isProgress: true,
      };
    case PICKUP_LIST + SUCCESS:
      return {
        ...state,
        pickups: action.payload
      }

    case CREATE_PICKUP + SUCCESS:
      return {
        ...state,
        response_type: 'create',
        isSuccess: true,
        successText: "New Pickup Created Successfully",
      }
    case STATE + CLEAR:
      return {
        ...state,
        response: null,
        isProgress: false,
        isError: false,
        errorText: "",
        isSuccess: false,
        successText: "",
      };
    default:
      return state;
  }
}

export default pickupReducer;
