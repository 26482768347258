import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import moment from 'moment';

const Summary = ({ form }) => {

  const Auth = useSelector((state) => state.Auth?.auth);
  // Extracted Data
  let Data = form.getFieldsValue(true);

  let dimensions = null;
  if(Data?.package_height && Data?.package_length && Data?.package_width){
    dimensions = Data?.package_height+' x '+Data?.package_length+' x '+Data?.package_width;
  }
  return (
    <>
      {Data &&
        <div className="grid">
          <div className="grid gap-2 grid-cols-1">
            <div className='pb-4'>
              <h1 className="text-xl text-gray-600 font-bold text-center pb-4 border-b-2">Pickup Address</h1>
              <p className="text-left  pt-2 text-center">{Data?.address1
              }</p><p>{Data?.province},{Data?.city},</p><p>{Data?.postal_code}</p>

            </div>
          </div>
          <div className="grid gap-2 grid-cols-2">
            <div className="px-2">
              <h1 className="text-xl text-gray-600 font-bold text-center pb-4 border-b-2">Pickup Details</h1>
              {Data?.package_weight > 0 &&
                <div className="flex items-center justify-between pb-4">
                  <p className="font-bold">Weight</p>
                  <p>{Data?.package_weight ? Data?.package_weight : 0} lbs</p>
                </div>}

                {dimensions && 
              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Dimensions</p>
                <p>{dimensions}</p>
              </div>}
              {dimensions && 
              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Unit</p>
                <p>{Data?.package_size_unit}</p>
              </div>}
            </div>

            <div className="px-2">
              <h1 className="text-xl text-gray-600 font-bold text-center pb-4 border-b-2">Other Info</h1>
              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Company Name</p>
                <p>{Data?.name}</p>
              </div>
              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Pickup Instruction</p>
                <p>{Data?.instruction}</p>
              </div>
              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Date</p>
                <p>{moment(Data?.pickup_date).format('MMMM Do, YYYY')}</p>
              </div>
              <div className="flex items-center justify-between pb-4">
                <p className="font-bold">Time</p>
                <p>{moment(Data?.pickup_time).format('h:mm A')}</p>
              </div>
            </div>
          </div>
        
        </div>}
    </>
  )
}

export default Summary