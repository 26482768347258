import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { SIGNIN, REGISTER, SIGNOUT, ACCOUNT_SETUP, VERIFY, AGREEMENT, RESEND_EMAIL, REQ, CLEAR, STATE, SUCCESS, FAILD } = _types;

export function* authSaga() {
  // Sign In
  yield takeLatest(SIGNIN + REQ, function* ({ payload }) {
    const response = yield _api.LoginUser({
      ...payload,
    });
    if (response?.non_field_errors) {
      yield put({ type: SIGNIN + FAILD, payload: response.non_field_errors[0] });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: SIGNIN + SUCCESS,
        payload: response,
      });
    }
  });

  // Sign Out
  yield takeLatest(SIGNOUT + REQ, function* () {
    yield put({ type: SIGNOUT });
  });

  // Register User
  yield takeLatest(REGISTER + REQ, function* ({ payload }) {
    const response = yield _api.RegisterUser({
      ...payload,
    });
    if (response?.error) {
      yield put({ type: REGISTER + FAILD, payload: response.error_description?.username[0] });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      if (response?.user) {
        yield put({
          type: REGISTER + SUCCESS,
          payload: response,
        });
      } else {
        yield put({ type: REGISTER + FAILD, payload: response.Error });
        yield delay(3000);
        yield put({ type: STATE + CLEAR });
      }
    }
  });

  // Account Setup
  yield takeLatest(ACCOUNT_SETUP + REQ, function* ({ payload }) {
    const response = yield _api.Account_setup(payload.data, payload.token);
    if (response?.error) {
      yield put({ type: ACCOUNT_SETUP + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: ACCOUNT_SETUP + SUCCESS,
        payload: response?.data,
      });
      yield put({ type: 'ACCOUNT_SETUP_FORM_CLEAR' });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

  // verify Setup
  yield takeLatest(VERIFY + REQ, function* ({ payload }) {
    const response = yield _api.verify_user(payload.token, payload.userId);
    if (response?.error) {
      yield put({ type: VERIFY + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: VERIFY + SUCCESS,
        payload: response?.data,
      });
    }
  });

  // User Agreement
  yield takeLatest(AGREEMENT + REQ, function* ({ payload }) {
    const response = yield _api.user_agreement(payload);
    if (response?.error) {
      yield put({ type: AGREEMENT + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: AGREEMENT + SUCCESS,
        payload: response,
      });
    }
  });

  // User Agreement
  yield takeLatest(RESEND_EMAIL + REQ, function* ({ payload }) {
    const response = yield _api.resend_email(payload);
    if (response?.error) {
      yield put({ type: RESEND_EMAIL + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: RESEND_EMAIL + SUCCESS,
        payload: response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(authSaga)]);
}
