import { _types } from "../types";
const { REQ, PICKUP_LIST, CREATE_PICKUP } = _types;
const actions = {
  get_pickups: (payload) =>
  ({
    type: PICKUP_LIST + REQ,
    payload: payload,
  }),
  create_pickup: (payload) =>
  ({
    type: CREATE_PICKUP + REQ,
    payload: payload,
  }),


};
export default actions;
