import { all, fork ,takeLatest, delay, put, takeEvery, call } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { REQ, CLEAR, STATE, SUCCESS, FAILD, PREP_LIST,CREATE_PREP,DELETE_PREP,UPDATE_PREP} = _types;

export function* prepSaga() {
 
  // Prep List
  yield takeLatest(PREP_LIST + REQ, function* ({ payload }) {
    const response = yield _api.get_preps_item(payload);
    if (response?.error) {
      yield put({ type: PREP_LIST + FAILD, payload:"Some thing Went Wrong"});
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: PREP_LIST + SUCCESS,
        payload: response?.data,
      });
    }
  });

 // Create Prep
 yield takeLatest(CREATE_PREP + REQ, function* ({ payload }) {
  const response = yield _api.create_prep(payload.data,payload.token);
    if(response?.status===200){
    yield put({
      type: CREATE_PREP + SUCCESS,
      payload: response?.data,
    })
    yield delay(3000);
    yield put({ type: STATE + CLEAR });
  }
});

 // Delete Prep
 yield takeLatest(DELETE_PREP + REQ, function* ({ payload }) {
  const response = yield _api.delete_prep(payload.token, payload.id);
  if (!response?.error) {
    yield put({
      type: DELETE_PREP + SUCCESS,
      payload: response?.data,
    });
    yield delay(3000);
    yield put({ type: STATE + CLEAR });
  }
});

  yield takeLatest(UPDATE_PREP + REQ, function* ({ payload }) {
    const response = yield _api.update_prep(payload.token, payload.data);
    if(response?.status===200){
      yield put({
        type: UPDATE_PREP + SUCCESS,
        payload: response?.data,
      })
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

}

export default function* rootSaga() {
  yield all([fork(prepSaga)]);
}
