// PLUGINS IMPORTS //
import React, { useState, useEffect } from "react"

// COMPONENTS IMPORTS //
import { Input, Form, Select,Typography } from 'antd';
import Service from '@helpers/GeoData'

const Pickup = ({ form }) => {


  // Hooks
  const [provinces, setProvinces] = useState(prefilledProvinces('Canada'));
  const [cities, setCites] = useState(prefilledCities('Canada', form.getFieldValue('province')));

  // Prefilled Provinces if exist
  function prefilledProvinces(val) {
    let provinces = [];
    if (val) {
      let res_province = Service.filter(item => item.name == val)[0];
      provinces = res_province?.states?.map(item => ({ value: item?.state_code, label: item?.name, cities: item.cities }));
    }
    return provinces
  }

  // Prefilled Cities if exist
  function prefilledCities(country, state) {

    let Cities = []
    if (country && state) {
      let res_province = Service.filter(item => item.name == country)[0];
      let provinces = res_province?.states?.filter(item => item.state_code == state)[0];
      Cities = provinces.cities?.map(item => ({ value: item.name, label: item.name }));
    }
    return Cities
  }


  // Province / State Change Handler
  async function handleProvince(val) {
    // Cites
    let res_cities = provinces?.filter(item => item.value === val)[0];
    let cities = res_cities.cities?.map(item => ({ value: item.name, label: item.name }));
    setCites(cities);
  }

  return (
    <>
      <div className="grid">
        <Typography.Title level={3}>Pickup Address</Typography.Title>
        {/* Row 1 */}
        <div className="grid-flow-row">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Company Name" size="large" />
          </Form.Item>
        </div>
        <div className="grid gap-2 grid-cols-2">
          <Form.Item
            name="address1"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Address Line 1" size="large" />
          </Form.Item>
          <Form.Item
              name="address2"
            >
              <Input

                placeholder="Address Line 2" size="large" />
            </Form.Item>
        </div>

        <div className="grid gap-2 grid-cols-2">
          <div>
            <Form.Item name="province"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select Province"
                onChange={handleProvince}
                options={provinces}
              />

            </Form.Item>
          </div>
          <div>
            <Form.Item name="city"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select city"
                options={cities}

              />
            </Form.Item></div>

        </div>
        <div className="grid gap-2 grid-cols-2">
          <div>
            <Form.Item
              name="postal_code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Postal Code" size="large" />
            </Form.Item></div>
          <div> <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            name="phone"
          >
            <Input placeholder="Phone No" size="large" />
          </Form.Item></div>

        </div>
      </div>
    </>
  )
}

export default Pickup
