import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { REQ, CLEAR, STATE, SUCCESS, FAILD, PICKUP_LIST, CREATE_PICKUP } = _types;

export function* pickupSaga() {



  // Shipment List
  yield takeLatest(PICKUP_LIST + REQ, function* ({ payload }) {
    const response = yield _api.get_pickups(payload);
    if (response?.error) {
      yield put({ type: PICKUP_LIST + FAILD, payload: "Some thing Went Wrong" });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: PICKUP_LIST + SUCCESS,
        payload: response?.data,
      });
    }
  });


  // Create New Pickup
  yield takeLatest(CREATE_PICKUP + REQ, function* ({ payload }) {
    const response = yield _api.create_pickup(payload.data, payload.token);
    if (response.status < 300) {
      yield put({
        type: CREATE_PICKUP + SUCCESS,
        payload: response?.data,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });

    }
  });

}

export default function* rootSaga() {
  yield all([fork(pickupSaga)]);
}
