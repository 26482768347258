import { _types } from "../types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
const { STATE, CLEAR, FAILD, SIGNIN, SIGNOUT, ACCOUNT_SETUP, AGREEMENT, RESEND_EMAIL, VERIFY, REGISTER, SUCCESS } = _types;
const initState = {
  isProgress: false,
  auth: null,
  isError: false,
  errorText: "",
  isSuccess: false,
  successText: "",
  register: "",
  profile: ""
};
function authReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        isProgress: true,
      };

    case SIGNIN + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        successText: "",
        auth: { ...action.payload },
      };

    case REGISTER + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        successText: action.payload.Message,
        register: action.payload.user,
        auth: { is_account_setup_completed: action.payload.is_account_setup_completed, is_verified: action.payload.is_verified, token: action.payload.token },
      };
    case REGISTER + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        register: "",
        isError: true,
        errorText: action.payload,
      };

    case SIGNIN + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        isError: true,
        errorText: action.payload,
      };
    case ACCOUNT_SETUP + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        successText: '',
        auth: { ...state.auth, is_account_setup_completed: true },
        profile: action.payload
      };
    case AGREEMENT + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        successText: '',
        auth: { ...state.auth, is_agree: true },
      };
    case VERIFY + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        successText: '',
        auth: { ...state.auth, is_verified: true },
      };
    case RESEND_EMAIL + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        successText: "Verification Email Sent Successfully!",
      };
    case VERIFY + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        isError: true,
        errorText: action.payload,
      };
    case ACCOUNT_SETUP + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        isError: true,
        errorText: action.payload,
      };

    case SIGNOUT:
      return initState;

    case STATE + CLEAR:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        isSuccess: false,
        successText: "",
      };
    default:
      return state;
  }
}
const persistConfig = {
  key: "authReducer",
  storage: storage,
  blacklist: [
    "isProgress",
    "isError",
    "register",
    "errorText",
    "isSuccess",
    "successText",
  ],
  whitelist: ["auth"],
};
export default persistReducer(persistConfig, authReducer);
