import React,{useEffect,useState,createContext,useContext} from 'react'


function ScreenModal({Show,children}) {
  useEffect(() => {
    if(Show){
      if(!document.querySelector('body').classList.contains('overflow-y-hidden')){
        document.querySelector('body').classList.add('overflow-y-hidden')
      }
    }else{
      document.querySelector('body').classList.remove('overflow-y-hidden')
    }
  }, [Show])
  
  return (
    <div className={!Show?'hidden':''} > <div className={'overflow-y-auto flex h-full min-h-screen bg-white flex-col transition-all duration-[800ms] fixed w-full '+(!Show?'top-[100%]':'top-[0]')}>
    {children}
  </div>
  </div>)}

export default ScreenModal