import { _types } from "../types";
const { 
  REQ,
  SHIPMENT_LIST,
  CREATE_SHIPMENT,
  UPDATE_SHIPMENT,
  EDIT_SHIPMENT,
  IMPORTED_SHIPMENT_LIST,
  SET_IMPORT_SHIPMENT,
  IMPORT_SHIPMENT,
  DELETE_SHIPMENT
 } = _types;
const actions = {
  get_shipments: (payload) =>
  ({
    type: SHIPMENT_LIST + REQ,
    payload: payload,
  }),
  get_imported_shipment: (payload) =>
  ({
    type: IMPORTED_SHIPMENT_LIST + REQ,
    payload: payload,
  }),
  create_shipment: (payload) =>
  ({
    type: CREATE_SHIPMENT + REQ,
    payload: payload,
  }),
  delete_shipment: (payload) =>
  ({
    type: DELETE_SHIPMENT + REQ,
    payload: payload,
  }),
  update_shipment: (payload) =>
  ({
    type: UPDATE_SHIPMENT + REQ,
    payload: payload,
  }),
  edit_shipment: (payload) =>
  ({
    type: EDIT_SHIPMENT + REQ,
    payload: payload,
  }),
  import_shipment: (payload) =>
  ({
    type: IMPORT_SHIPMENT + REQ,
    payload: payload,
  }),

};
export default actions;
