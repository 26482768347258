import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { Form, Typography, Button, Steps } from 'antd';

import Shipment_Action from '@redux/shipment/actions';
import LoadingScreen from "@components/LoadingScreen";
import { usePopupHandler } from '@layouts/PopupHandler';

// Pages and Forms
import Recipient from './forms/Recipient'
import VerifyAddress from './forms/VerifyAddress'
import Contents from './forms/Contents'
import PackageDetails from './forms/PackageDetails'
import Postage from './forms/Postage'
import Summary from './forms/Summary'

// Render Pages Handler
const renderStepContent = (step, form, setAddressValid, thirdpatyList) => {
  switch (step) {
    case 0:
      return <Recipient form={form} />
    case 1:
      return <VerifyAddress form={form} setAddressValid={setAddressValid} />
    case 2:
      return <Contents form={form} />
    case 3:
      return <PackageDetails form={form} />
    case 4:
      return <Postage form={form} thirdparty={thirdpatyList} />
    case 5:
      return <Summary form={form} />
  }
}

export default function ShipmentCreate({ activeShipment }) {
  const [form] = Form.useForm();
  const { closeModal } = usePopupHandler()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Auth = useSelector((state) => state.Auth?.auth);
  const Shipment = useSelector((state) => state?.Shipment);
  const [serviceList, setServiceList] = useState([])
  const [FormState, setFormState] = useState({
    id: activeShipment.id,
    name: activeShipment.name,
    address1: activeShipment.address1,
    address2: activeShipment.address2,
    country: activeShipment.country,
    city: activeShipment.city,
    province: activeShipment.province,
    postal_code: activeShipment.postal_code,
    phone: activeShipment.phone,
    tax_id: activeShipment.tax_id,
    email: activeShipment.email,
    package_content: activeShipment.package_content,
    package_description: activeShipment.package_description,
    retail_value: activeShipment.retail_value,
    package_weight: activeShipment.package_weight,
    pick_type: activeShipment.pick_type,
    order_id: activeShipment.order_id,
    has_postage: activeShipment.has_postage,
    package_type: activeShipment.package_type,
    package_type_option: activeShipment.package_type_option,
    is_cross_border: activeShipment.is_cross_border,
    package_length: activeShipment.package_length,
    package_width: activeShipment.package_width,
    package_height: activeShipment.package_height,
    package_size_unit: activeShipment.package_size_unit,
  })
  const [activeStep, setActiveStep] = useState(activeShipment?.activeStep ?? 0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAddressValid, setAddressValid] = useState(false)

  let thirdpatyList = {
    get: function () {
      return serviceList
    },
    set: function (val) {
      setServiceList(val)
    }
  }

  // Step Label Generator
  const steps = ["Recipient", "Verify Address", "Contents", "Package Details", "Postage Info", "Summary"]
  const items = steps.map((item) => ({
    key: item,
    title: item,
  }));

  // Real Time Address Setter
  useEffect(() => {
    setAddressValid(form.getFieldValue('validAddress'))
  }, [form.getFieldValue('validAddress')])

  // Edit Shipment Response Handler
  useEffect(() => {
    if (Shipment.isSuccess && Shipment.response_type === 'edit') {
      Swal.fire({
        title: 'Successfull',
        html: Shipment?.successText,
        icon: 'success',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          closeModal()
          form.resetFields();
        }
      })
    }
    else if (Shipment.isError && Shipment.response_type === 'edit') {
      Swal.fire(
        'error',
        Shipment?.errorText,
        'error',
      )
    }
  }, [Shipment.isSuccess, Shipment.isError])

  // Previous and Next Handler
  const next = () => {
    form
      .validateFields()
      .then((response) => {
        setActiveStep(activeStep + 1)
      })
      .catch((error) => console.log(error));
  };
  const prev = () => {
    setActiveStep(activeStep - 1)
  };

  // Handle Final Submission
  const handleSubmit = () => {
    let data = form.getFieldsValue(true);
    if (typeof form.getFieldValue('postage_type') === "object") {
      data = { ...form.getFieldsValue(true), postage_type: form.getFieldValue('postage_type')?.service };
    }
    dispatch(Shipment_Action.edit_shipment({ data, token: Auth?.token }))
  }
  if (activeShipment) console.log(activeShipment);
  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className='header py-4 px-4 bg-[#00443c]'>
        <div className='flex w-full justify-between'>
          <div>    <Typography.Title level={3}>
            <span className='text-white'>    Edit Shipment</span>
          </Typography.Title></div>
          <div> <Button type='text' className='text-white' onClick={() => closeModal()} >
            Close
          </Button></div>
        </div>
      </div>
      <Form
        initialValues={FormState}
        form={form}
        layout="vertical"
      >

        <div className='content-center justify-center items-center contents min-h-screen'>
          <div className="shadow-lg p-8 mb-6">
            <Steps current={activeStep} items={items} size="small" />
          </div>
          <div className="shadow-xl p-6 mt-[7.292vw] flex flex-col items-center justify-center text-center border xs:w-[350px] sm:w-[550px] md:w-[800px] my-auto mx-auto">
            <div className="steps-content w-full">{renderStepContent(activeStep, form, setAddressValid, thirdpatyList)}</div>
            <div className="steps-action">
              {activeStep < steps.length - 1 && (
                <Button type="primary" disabled={activeStep == 1 ? isAddressValid : false} className={activeStep == 1 ? isAddressValid ? "pointer-events-none" : "pointer-events-auto" : "pointer-events-auto"} onClick={() => next()}>
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button type="primary" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              )}
              {activeStep > 0 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}