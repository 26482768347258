import React, { useState, useEffect } from 'react'
import { Input, Form, Radio, Result, Spin, Typography, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SweetAlertIcon } from "sweetalert2";
import Service from '@validations/AddressValidator';
import { XMLParser, XMLBuilder } from 'fast-xml-parser'

const VerifyAddress = ({ form, setAddressValid }) => {
  // Validation State
  const [isValidAddress, setValidAddress] = useState(false);
  const [validDescription, setValidDescription] = useState('')
  const [isCrossBorder, setIsCrossBorder] = useState(form.getFieldValue('is_cross_border'))


  // Extracted Data
  let Country = form.getFieldValue('country');
  let Province = form.getFieldValue('province');
  let City = form.getFieldValue('city');
  let PostalCode = form.getFieldValue('postal_code');
  let Address1 = form.getFieldValue('address1');
  let Address2 = form.getFieldValue('address2');


  // Validation Hanlder
  async function validationHandler() {
    setAddressValid(true);
    let response = await Service.validateAddress({ Country, Province, City, PostalCode, Address1, Address2 }).then(response => response);
    const parser = new XMLParser();
    const builder = new XMLBuilder();
    let Obj = parser.parse(response);
    if (Obj.Error) {
      setValidAddress(false);
      setValidDescription(Obj.Error.Description);
      setAddressValid(true);

    }
    if (Obj.AddressValidateResponse) {

      let data = Obj.AddressValidateResponse.Address;
      if (data?.Error) {
        setValidAddress(false);
        setAddressValid(true);

        setValidDescription(data?.Error?.Description);
      } else {
        setValidAddress(true);
        setAddressValid(false);

        let address = Address1 + ' ' + City + ', ' + Province + ' ' + PostalCode + ', ' + Country
        setValidDescription(address);
      }

    }

  }
  useEffect(() => {
    if (Country === "United States") {
      validationHandler();
    } else {
      setValidAddress(true);
      let address = Address1 + ' ' + City + ', ' + Province + ' ' + PostalCode + ', ' + Country
      setValidDescription(address);
      setAddressValid(false);
    }
  }, []);


  return (
    <div className="grid">
      <Typography.Title level={3}>Validate Your Address</Typography.Title>
      {/* Row 1 */}
      <div className="grid-flow-row">
        {!validDescription ?
          <div className='h-40 flex justify-center items-center text-center'>   <Spin tip="Validating Address..." indicator={<LoadingOutlined />} /></div> :
          <Result
            status={isValidAddress ? "success" : "error"}
            title={isValidAddress ? "Address Valid!" : "Invalid Address!"}
            subTitle={validDescription}
          />}
      </div>
      <div className='grid gap-2 grid-cols-2'>
        <div>
          <Form.Item
            name="is_cross_border"
            rules={[
              {
                required: true,

              },
            ]}
          >
            {/* <Radio.Group onChange={e => {setIsCrossBorder(e.target.value),e.target.value?form.setFieldValue('has_postage',null):null}}> */}
            <Radio.Group onChange={e => {setIsCrossBorder(e.target.value),form.setFieldValue('has_postage',null)}}>
              <Space direction="vertical" className='text-left justify-self-start'>
                <Typography.Text><b>Is this Cross Border Shipment?</b></Typography.Text>
                <Radio value={true} >Yes</Radio>
                <Radio value={false}  >No </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="has_postage"
            rules={[
              {
                required: true,

              },
            ]}
          >
            <Radio.Group onChange={e => form.setFieldValue('has_postage', e.target.value)}>
              <Space direction="vertical" className='text-left justify-self-start'>
                <Typography.Text><b>Do you Need Postage?</b></Typography.Text>
                {isCrossBorder 
                ? (
                  <>
                    <Radio value='Yes'>Yes</Radio>
                    <Radio value='No'>No</Radio>
                  </>
                ) : (
                  <Radio value='Yes'>Yes</Radio>
                )}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>

      </div>


    </div>
  )
}

export default VerifyAddress