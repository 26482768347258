import './App.css';
import { Provider } from 'react-redux';
import { store, persistor} from './redux/store';
import { PersistGate } from 'redux-persist/es/integration/react'  
import Routes from "./routes/router";
function App() {
 

  return (
    <PersistGate 
    loading={<div>Loading...</div>}
    persistor={persistor}>
    <Provider store={store}>
 
     <Routes/>
    
  </Provider>
  </PersistGate>
  );
}

export default App;
