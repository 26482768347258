import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "@config/siteConfig";

// import qs from "qs";

const _api = {
  get_pickups: async function (token) {
    return axios
      .get(API_URL + "pickup-details/", {
        headers: {'Content-Type' : 'application/json; charset=utf-8',
        Authorization: 'Token '+ token
      },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  create_pickup: async function (data, token) {
    return axios
      .post(API_URL + "pickup-details/", data, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Token ' + token
        },
      })
      .then(function (response) {
        if(response.data.status!==200){
          Swal.fire(
            'Error!',
           response?.data?.message,
            'error',
          );
        }
        return response?.data;
      })
      .catch(function (error) {
        Swal.fire(
          'Error!',
            "Some thing Went wrong!",
          'error',
        );
        return error?.response.data;
      });
  },

 
};
export default _api;
