import { _types } from "../types";

const { STATE, CLEAR, FAILD, SUCCESS, SHIPMENT_LIST, CREATE_SHIPMENT, UPDATE_SHIPMENT, EDIT_SHIPMENT, DELETE_SHIPMENT, IMPORT_SHIPMENT, IMPORTED_SHIPMENT_LIST} = _types;
const initState = {
  isProgress: false,
  shipments: null,
  batches: null,
  batch: null,
  isError: false,
  errorText: "",
  isSuccess: false,
  successText: "",
  response_type: null,
};
function shipmentReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        isProgress: true,
      };
    case SHIPMENT_LIST + SUCCESS:
      return {
        ...state,
        shipments: action.payload
      };
    case IMPORTED_SHIPMENT_LIST + SUCCESS:
      return {
        ...state,
        imported_shipments: action.payload
      }
    case CREATE_SHIPMENT + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        response: action.payload,
        successText: "New Shipment Created Successfully",
      };
    case CREATE_SHIPMENT + FAILD:
      return {
        ...state,
        isError: true,
        errorText: action.payload,
      };
    case IMPORT_SHIPMENT + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        successText: "Shipment has been imported Successfully",
        response_type: "import"
      };
    case IMPORT_SHIPMENT + FAILD:
      return {
        ...state,
        isError: true,
        errorText: action.payload,
        response_type: "import"
      };
    case UPDATE_SHIPMENT + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        successText: "Shipment has been updated successfully",
        response_type: "update"
      };
    case UPDATE_SHIPMENT + FAILD:
      return {
        ...state,
        isError: true,
        errorText: action.payload,
        response_type: "update"
      };
    case EDIT_SHIPMENT + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        successText: "Shipment has been edited successfully",
        response_type: "edit"
      };
    case EDIT_SHIPMENT + FAILD:
      return {
        ...state,
        isError: true,
        errorText: action.payload,
        response_type: "edit"
      };
    case DELETE_SHIPMENT + SUCCESS:
      return {
        ...state,
        isSuccess: true,
        response_type: "delete",
        successText: "Shipment has been deleted successfully",
      };
    case EDIT_SHIPMENT + FAILD:
      return {
        ...state,
        isError: true,
        errorText: action.payload,
        response_type: "delete"
      };
    case STATE + CLEAR:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        isSuccess: false,
        successText: "",
        response_type: null
      };
    default:
      return state;
  }
}

export default shipmentReducer;
